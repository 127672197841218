import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, Marker, Circle, OverlayView, Polygon, useLoadScript } from '@react-google-maps/api';
import { MapPin, Eye, EyeOff, Trash2 } from 'lucide-react';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBs6odIntk0EbNgKpMKjLoe6_1ttBlGmbA';
const libraries = ["places", "geometry", "drawing"];

const MapControls = ({ map, userLocation, onCenterOnLocation, showAvailable, setShowAvailable, activeTab }) => {
  if (!map) return null;

  return (
    <div className="absolute right-4 top-1/2 -translate-y-1/2 z-20">
      <div className="bg-white/90 backdrop-blur rounded-lg shadow-lg flex flex-col gap-2 p-2">
        <button
          onClick={onCenterOnLocation}
          disabled={!userLocation}
          className="p-2 hover:bg-gray-100 transition-colors rounded-lg 
                   disabled:opacity-50 disabled:cursor-not-allowed"
          title="Center on your location"
        >
          <MapPin className={userLocation ? "text-orange-500" : "text-gray-400"} />
        </button>
        {activeTab === 'requests' && (
          <button
            onClick={() => setShowAvailable(!showAvailable)}
            className="p-2 hover:bg-gray-100 transition-colors rounded-lg"
            title={showAvailable ? "Show all requests" : "Show available only"}
          >
            {showAvailable ? (
              <Eye className="text-green-500" />
            ) : (
              <EyeOff className="text-orange-500" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

const calculateCenter = (points) => {
  if (!points || points.length === 0) return null;
  const sumLat = points.reduce((sum, point) => sum + point.lat, 0);
  const sumLng = points.reduce((sum, point) => sum + point.lng, 0);
  return {
    lat: sumLat / points.length,
    lng: sumLng / points.length
  };
};

const MainMap = ({
  map,
  setMap,
  center,
  userLocation,
  requests,
  businesses,
  activeTab = 'requests',
  showAvailable,
  setShowAvailable,
  selectedRequest,
  selectedBusiness,
  userId,
  onDeleteRequest,
  handleItemSelect // Added prop
}) => {
  const [selectedBoundary, setSelectedBoundary] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  });

  useEffect(() => {
    if (selectedRequest?.id) {
      setSelectedBoundary(selectedRequest.id);
      if (map && selectedRequest.makerLocation) {
        if (selectedRequest.boundaryType === 'custom' && selectedRequest.boundary?.points) {
          const bounds = new window.google.maps.LatLngBounds();
          selectedRequest.boundary.points.forEach(point => {
            bounds.extend({ lat: point.lat, lng: point.lng });
          });
          map.fitBounds(bounds);
        } else {
          const location = {
            lat: Number(selectedRequest.makerLocation.lat),
            lng: Number(selectedRequest.makerLocation.lng)
          };
          map.panTo(location);
          map.setZoom(12);
        }
      }
    }
  }, [selectedRequest, map]);

  useEffect(() => {
    if (selectedBusiness?.id && map && selectedBusiness.location) {
      const location = {
        lat: Number(selectedBusiness.location.lat),
        lng: Number(selectedBusiness.location.lng)
      };
      map.panTo(location);
      map.setZoom(18);
    }
  }, [selectedBusiness, map]);

  const onMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    mapInstance.setMapTypeId("hybrid");
  }, [setMap]);

  const centerOnLocation = useCallback(() => {
    if (map && userLocation) {
      map.panTo(userLocation);
      map.setZoom(15);
    }
  }, [map, userLocation]);

  const calculateDistance = (requestLocation) => {
    if (!userLocation || !requestLocation?.lat || !requestLocation?.lng) return null;
    
    try {
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(
          Number(userLocation.lat), 
          Number(userLocation.lng)
        ),
        new window.google.maps.LatLng(
          Number(requestLocation.lat), 
          Number(requestLocation.lng)
        )
      );
      
      return distance;
    } catch (error) {
      console.error('Error calculating distance:', error);
      return null;
    }
  };

  const isRequestAvailable = useCallback((request) => {
    if (!userLocation) return false;
    
    if (request.authorId === userId) return true;

    if (request.boundaryType === 'custom' && request.boundary?.points) {
      const userLatLng = new window.google.maps.LatLng(userLocation.lat, userLocation.lng);
      const polygon = new window.google.maps.Polygon({
        paths: request.boundary.points.map(point => ({
          lat: point.lat,
          lng: point.lng
        }))
      });
      return window.google.maps.geometry.poly.containsLocation(userLatLng, polygon);
    } else {
      const distance = calculateDistance(request.makerLocation);
      const radiusInMeters = (request.boundary?.radius || 5) * 1000;
      return distance !== null && distance <= radiusInMeters;
    }
  }, [userLocation, userId, calculateDistance]);

  const formatDistance = (meters) => {
    if (meters === null) return 'Calculating distance...';
    
    if (meters >= 1000000) {
      return `${(meters / 1609340).toFixed(0)}k miles away`;
    }
    if (meters >= 1609.34) {
      return `${(meters / 1609.34).toFixed(0)} miles away`;
    }
    if (meters >= 1000) {
      return `${(meters / 1000).toFixed(1)}km away`;
    }
    return `${Math.round(meters)}m away`;
  };

  if (loadError || !isLoaded) return null;

  return (
    <div className="fixed inset-0 w-screen h-screen">
      <GoogleMap
        mapContainerClassName="w-full h-screen"
        center={{ lat: 20, lng: 0 }}
        zoom={3}
        onLoad={onMapLoad}
        onClick={() => setSelectedBoundary(null)}
        options={{
          mapTypeId: "hybrid",
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: true,
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_CENTER
          },
          fullscreenControlOptions: {
            position: window.google.maps.ControlPosition.BOTTOM_RIGHT
          },
          gestureHandling: 'greedy',
          minZoom: 2,
          maxZoom: 21,
          styles: [
            {
              featureType: "all",
              elementType: "labels",
              stylers: [{ visibility: "off" }]
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [{ color: "#001B44" }]
            }
          ],
          streetViewControl: false,
          backgroundColor: '#000000',
        }}
      >
        {userLocation && (
          <Marker
            position={userLocation}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              fillColor: '#f97316',
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: '#fff',
              scale: 8,
            }}
          />
        )}
        
        {activeTab === 'businesses' && businesses?.map((business) => (
          <React.Fragment key={business.id}>
            <Marker
              position={business.location}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: '#22c55e',
                fillOpacity: 1,
                strokeColor: '#ffffff',
                strokeWeight: 2,
                scale: selectedBusiness?.id === business.id ? 10 : 8,
              }}
              onClick={() => handleItemSelect(business, 'business')}
            />
            {selectedBusiness?.id === business.id && business.boundary && (
              <Polygon
                paths={business.boundary.points}
                options={{
                  strokeColor: '#22c55e',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: '#22c55e',
                  fillOpacity: 0.1,
                }}
              />
            )}
          </React.Fragment>
        ))}
        
        {activeTab === 'requests' && requests?.map((request) => {
          if (!request.makerLocation?.lat || !request.makerLocation?.lng) return null;

          const isCustomBoundary = request.boundaryType === 'custom' && request.boundary?.points?.length > 0;
          const location = isCustomBoundary ? 
            calculateCenter(request.boundary.points) : 
            {
              lat: Number(request.makerLocation.lat),
              lng: Number(request.makerLocation.lng)
            };

          if (!location) return null;

          const isAvailable = isRequestAvailable(request);
          const isSelected = selectedBoundary === request.id;
          const isOwner = request.authorId === userId;
          const distance = calculateDistance(location);

          return (
            <React.Fragment key={request.id}>
              <Marker
                position={location}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  fillColor: isSelected ? '#f97316' : (isAvailable ? '#22c55e' : '#ef4444'),
                  fillOpacity: 1,
                  strokeWeight: 2,
                  strokeColor: '#fff',
                  scale: isSelected ? 10 : 8,
                }}
                onClick={() => {
                  setSelectedBoundary(request.id);
                  if (isCustomBoundary && map) {
                    const bounds = new window.google.maps.LatLngBounds();
                    request.boundary.points.forEach(point => {
                      bounds.extend({ lat: point.lat, lng: point.lng });
                    });
                    map.fitBounds(bounds);
                  } else {
                    map.panTo(location);
                    map.setZoom(12);
                  }
                }}
              />

              {isSelected && (
                <>
                  {isCustomBoundary ? (
                    <Polygon
                      paths={request.boundary.points.map(point => ({
                        lat: point.lat,
                        lng: point.lng
                      }))}
                      options={{
                        strokeColor: '#f97316',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#f97316',
                        fillOpacity: 0.1,
                      }}
                    />
                  ) : (
                    <Circle
                      center={location}
                      radius={(request.boundary?.radius || 5) * 1000}
                      options={{
                        strokeColor: '#f97316',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#f97316',
                        fillOpacity: 0.1,
                      }}
                    />
                  )}
                </>
              )}

              <OverlayView
                position={location}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div className={`bg-white/90 backdrop-blur shadow-lg px-3 py-1 rounded-lg 
                             transform -translate-y-full mb-2 cursor-pointer
                             ${isSelected ? 'scale-110' : ''} transition-all duration-300`}>
                  <div className="flex items-center gap-2">
                    <div className="text-lg font-bold text-orange-500">
                      ${request.reward}
                    </div>
                    {isOwner && isSelected && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteRequest(request.id);
                        }}
                        className="p-1 hover:bg-red-100 rounded-full transition-colors"
                      >
                        <Trash2 className="w-4 h-4 text-red-500" />
                      </button>
                    )}
                  </div>
                  {isSelected && (
                    <div className="text-xs text-gray-600 mt-1">
                      {isOwner ? (
                        <span className="text-blue-500">Your Request</span>
                      ) : isCustomBoundary ? (
                        <span className={isAvailable ? "text-green-500" : "text-red-500"}>
                          {isAvailable ? 'Available' : 'Not Available'} • Custom Region
                        </span>
                      ) : isAvailable ? (
                        <span className="text-green-500">Available • {request.boundary?.address || 'Local Area'}</span>
                      ) : (
                        <span className="text-red-500">Not Available • {formatDistance(distance)}</span>
                      )}
                    </div>
                  )}
                </div>
              </OverlayView>
            </React.Fragment>
          );
        })}
      </GoogleMap>
      
      <MapControls 
        map={map}
        userLocation={userLocation}
        onCenterOnLocation={centerOnLocation}
        showAvailable={showAvailable}
        setShowAvailable={setShowAvailable}
        activeTab={activeTab}
      /></div>
    );
  };
  
  export default MainMap;