// ScopeSection.js
import React from 'react';
import { Info, Check, Pencil } from 'lucide-react';
import LocationSearch from './LocationSearch';
import DrawingMap from './DrawingMap';

const ScopeSection = ({
  formState,
  handleInputChange,
  userLocation,
  handleRegionChange,
  locationError,
  setLocationError,
  selectedLocation,
  setSelectedLocation,
  handleLocationSelect
}) => {
  return (
    <div>
      <label className="block text-gray-400 mb-2">Scope</label>
      <div className="flex gap-6 mb-2">
        <label className="relative flex items-center cursor-pointer group">
          <input
            type="radio"
            name="scope"
            value="local"
            checked={formState.scope === 'local'}
            onChange={(e) => handleInputChange('scope', e.target.value)}
            className="peer sr-only"
          />
          <div
            className="w-5 h-5 border-2 border-gray-400 rounded-full 
                          peer-checked:border-orange-500 peer-checked:bg-orange-500 
                          transition-all"
          ></div>
          <span className="text-white ml-2 group-hover:text-orange-500 transition-colors">
            Local Only
          </span>
        </label>
        <label className="relative flex items-center cursor-pointer group">
          <input
            type="radio"
            name="scope"
            value="global"
            checked={formState.scope === 'global'}
            onChange={(e) => handleInputChange('scope', e.target.value)}
            className="peer sr-only"
          />
          <div
            className="w-5 h-5 border-2 border-gray-400 rounded-full 
                          peer-checked:border-orange-500 peer-checked:bg-orange-500 
                          transition-all"
          ></div>
          <span className="text-white ml-2 group-hover:text-orange-500 transition-colors">
            Global
          </span>
        </label>
      </div>

      {/* Scope Description */}
      <div className="text-gray-400 text-sm mb-4 flex items-start gap-2">
        <Info size={16} className="flex-shrink-0 mt-0.5" />
        <span>
          {formState.scope === 'local'
            ? 'Local requests are only visible to users within your selected area'
            : 'Global requests are visible to all users worldwide'}
        </span>
      </div>

      {/* Anonymous Option for Global Scope */}
      {formState.scope === 'global' && (
        <div className="mt-4">
          <label className="flex items-center cursor-pointer group">
            <input
              type="checkbox"
              checked={formState.isAnonymous}
              onChange={(e) => handleInputChange('isAnonymous', e.target.checked)}
              className="sr-only peer"
            />
            <div
              className="w-5 h-5 border-2 border-gray-400 rounded 
                            peer-checked:border-orange-500 peer-checked:bg-orange-500 
                            transition-all flex items-center justify-center"
            >
              {formState.isAnonymous && <Check size={12} className="text-white" />}
            </div>
            <span className="text-white ml-2 group-hover:text-orange-500 transition-colors">
              Post Anonymously
            </span>
          </label>
          <p className="text-gray-400 text-sm mt-2 ml-7">
            Your request will be visible globally but your identity will be hidden
          </p>
        </div>
      )}

      {/* Location Services Warning */}
      {formState.scope === 'local' && !userLocation && (
        <div className="mt-4 p-4 bg-red-500/10 rounded-lg border border-red-500/20">
          <p className="text-red-400 text-sm flex items-center gap-2">
            <Info size={16} />
            Location services must be enabled for local requests
          </p>
        </div>
      )}

      {/* Local Scope Options */}
      {formState.scope === 'local' && userLocation && (
        <div className="mt-4 space-y-4">
          <div>
            <label className="block text-gray-400 mb-2">Boundary Type</label>
            <div className="flex gap-6 mb-4">
              <label className="relative flex items-center cursor-pointer group">
                <input
                  type="radio"
                  name="boundaryType"
                  value="radius"
                  checked={formState.boundaryType === 'radius'}
                  onChange={(e) => handleInputChange('boundaryType', e.target.value)}
                  className="peer sr-only"
                />
                <div
                  className="w-5 h-5 border-2 border-gray-400 rounded-full 
                                peer-checked:border-orange-500 peer-checked:bg-orange-500 
                                transition-all"
                ></div>
                <span className="text-white ml-2 group-hover:text-orange-500 transition-colors flex items-center gap-2">
                  <span>Radius</span>
                  <span className="text-gray-400 text-sm">(circular area around a point)</span>
                </span>
              </label>
              <label className="relative flex items-center cursor-pointer group">
                <input
                  type="radio"
                  name="boundaryType"
                  value="custom"
                  checked={formState.boundaryType === 'custom'}
                  onChange={(e) => handleInputChange('boundaryType', e.target.value)}
                  className="peer sr-only"
                />
                <div
                  className="w-5 h-5 border-2 border-gray-400 rounded-full 
                                peer-checked:border-orange-500 peer-checked:bg-orange-500 
                                transition-all"
                ></div>
                <span className="text-white ml-2 group-hover:text-orange-500 transition-colors flex items-center gap-2">
                  <span>Custom Region</span>
                  <span className="text-gray-400 text-sm">(draw your own area)</span>
                </span>
              </label>
            </div>

            {formState.boundaryType === 'radius' ? (
              <>
                <LocationSearch
                  formState={formState}
                  handleInputChange={handleInputChange}
                  userLocation={userLocation}
                  setLocationError={setLocationError}
                  handleLocationSelect={handleLocationSelect}
                />
                <div className="mt-4">
                  <div className="flex justify-between">
                    <label className="block text-gray-400 mb-2">Radius (miles)</label>
                    <span className="text-gray-400 text-sm">
                      People within this range will see your request
                    </span>
                  </div>
                  <input
                    type="range"
                    min="1"
                    max="100"
                    value={formState.radius}
                    onChange={(e) => handleInputChange('radius', e.target.value)}
                    className="w-full accent-orange-500"
                  />
                  <div className="flex justify-between text-gray-400 text-sm mt-1">
                    <span>1 mile</span>
                    <span>{formState.radius} miles</span>
                    <span>100 miles</span>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <p className="text-gray-400 mb-2 flex items-center gap-2">
                  <Pencil size={16} />
                  Draw your custom region on the map
                </p>
                <DrawingMap userLocation={userLocation} onRegionChange={handleRegionChange} />
                {formState.customBounds && (
                  <p className="text-gray-400 text-sm mt-2 flex items-center gap-2">
                    <Info size={16} />
                    Area: {formState.customBounds.area.toFixed(2)} sq km
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScopeSection;
