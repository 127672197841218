import React from 'react';
import { Clock } from 'lucide-react';

const TransactionHistory = ({ isOpen, transactions = [] }) => {
  if (!isOpen) return null;
  
  return (
    <div className="fixed left-0 top-0 h-screen bg-[#1a1f2e] shadow-xl z-40 w-[320px]">
      <div className="h-full flex flex-col">
        <div className="p-6">
          <h2 className="text-xl text-white font-medium">History</h2>
        </div>

        <div className="flex-1 overflow-y-auto">
          {transactions.map((tx, index) => (
            <div key={index} className="px-6 py-4 border-b border-white/10 hover:bg-white/5">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  {tx.type === 'sent' ? (
                    <span className="text-red-500">-${tx.amount}</span>
                  ) : (
                    <span className="text-green-500">+${tx.amount}</span>
                  )}
                </div>
                <span className="text-sm text-white/40">{tx.time}</span>
              </div>
              <div className="text-sm text-white/60 mt-1">Request #{tx.requestId}</div>
              <div className="text-sm text-white/80 mt-1">{tx.description}</div>
            </div>
          ))}
          {transactions.length === 0 && (
            <div className="p-6 text-center text-white/40">
              No transactions yet
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;