// lib/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC8mgwsJwXSa6mLkSw6q5QX0ssek-l5E5U",
  authDomain: "locallens-5cf17.firebaseapp.com",
  databaseURL: "https://locallens-5cf17-default-rtdb.firebaseio.com",
  projectId: "locallens-5cf17",
  storageBucket: "locallens-5cf17.firebasestorage.app",
  messagingSenderId: "876205328855",
  appId: "1:876205328855:web:10b96a98b2dc981c126392",
  measurementId: "G-5108XLEPC0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db, analytics };