import React, { useState, useEffect } from 'react';
import { X, Store, DollarSign, Globe, Users, MessageSquare, TrendingUp, MapPin } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const MASTER_WALLET = "Ai5oNL8qvQGE2dbYjzXahYvT44KoQ73zrUwsoPNr6PNB";

const BusinessDetailsPanel = ({ business, onClose, userLocation }) => {
  const [priceHistory, setPriceHistory] = useState([]);
  const [shareAmount, setShareAmount] = useState('1');
  const [selectedSection, setSelectedSection] = useState('overview');
  
  // Mock data - replace with real data
  const [owners] = useState({
    governance: [
      { wallet: MASTER_WALLET, shares: 510000, location: { lat: 9.0222, lng: 38.7468 }, label: 'Master Wallet' },
      { wallet: "AnotherGovernanceWallet123", shares: 100000, location: { lat: 9.0250, lng: 38.7500 }, label: 'City Council' }
    ],
    regular: [
      { wallet: "RegularWallet456", shares: 50000, location: { lat: 9.0300, lng: 38.7550 }, label: 'Local Investor' },
      { wallet: "RegularWallet789", shares: 40000, location: { lat: 9.0280, lng: 38.7520 }, label: 'Community Fund' }
    ],
    totalShares: 1000000
  });

  const [businessRequests] = useState([
    { id: 1, title: 'Customer Feedback Survey', reward: 2.5, responses: 12, timeLeft: '2 days' },
    { id: 2, title: 'Local Market Research', reward: 5.0, responses: 8, timeLeft: '5 hours' }
  ]);

  const [receivedInformation] = useState([
    { id: 1, title: 'Competition Analysis', value: 1.2, from: 'Local Analyst', date: '2 days ago' },
    { id: 2, title: 'Customer Demographics', value: 0.8, from: 'Market Research', date: '5 days ago' }
  ]);

  useEffect(() => {
    const points = [];
    let lastPrice = 0.09;
    for (let i = 0; i < 50; i++) {
      const change = (Math.random() - 0.5) * 0.01;
      lastPrice = Math.max(0.001, lastPrice + change);
      points.push({
        time: i,
        price: lastPrice
      });
    }
    setPriceHistory(points);
  }, [business.id]);

  const calculateDistance = (location) => {
    if (!userLocation || !location) return null;
    
    const R = 6371; // Earth's radius in km
    const dLat = (location.lat - userLocation.lat) * Math.PI / 180;
    const dLon = (location.lng - userLocation.lng) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(userLocation.lat * Math.PI / 180) * Math.cos(location.lat * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c;
    
    return distance < 1 ? `${(distance * 1000).toFixed(0)}m` : `${distance.toFixed(1)}km`;
  };

  return (
    <div className="fixed top-44 right-4 w-[400px] max-h-[calc(100vh-200px)] bg-[#1a1f2e] overflow-y-auto rounded-lg border border-white/10">
      {/* Header */}
      <div className="p-6">
        <div className="flex justify-between items-start">
          <div>
            <h2 className="text-xl text-white">{business.name}</h2>
            <p className="text-sm text-white/60">{business.address}</p>
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={20} />
          </button>
        </div>

        <div className="flex justify-between items-start mt-4">
          <div>
            <div className="text-2xl font-bold text-white">0.1045 SOL</div>
            <div className="flex items-center text-green-500">
              <TrendingUp className="w-4 h-4 mr-1" />
              <span>14.94%</span>
            </div>
          </div>
          <div className="text-right">
            <div className="text-white/60">Market Value</div>
            <div className="text-2xl font-bold text-white">0 SOL</div>
          </div>
        </div>
      </div>

      {/* Price Chart */}
      <div className="px-6 pb-6">
        <div className="h-48">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={priceHistory}>
              <XAxis dataKey="time" hide />
              <YAxis hide />
              <Tooltip content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="bg-[#1a1f2e] p-2 rounded border border-white/10">
                      <p className="text-white text-sm">{`${payload[0].value.toFixed(4)} SOL`}</p>
                    </div>
                  );
                }
                return null;
              }} />
              <Line 
                type="monotone" 
                dataKey="price" 
                stroke="#22c55e" 
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Tabs */}
      <div className="px-6 border-t border-white/10">
        <div className="flex gap-4">
          {['Overview', 'Owners', 'Requests', 'Information'].map((tab) => (
            <button
              key={tab.toLowerCase()}
              onClick={() => setSelectedSection(tab.toLowerCase())}
              className={`py-4 text-sm font-medium ${
                selectedSection === tab.toLowerCase()
                  ? 'text-white border-b-2 border-green-500'
                  : 'text-white/60 hover:text-white'
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      {selectedSection === 'overview' && (
        <div className="px-6 py-4 border-t border-white/10">
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <Store className="w-5 h-5 text-green-500" />
              <div>
                <div className="text-white font-medium">About</div>
                <div className="text-white/60 text-sm">{business.description || "they sell gas"}</div>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Globe className="w-5 h-5 text-green-500" />
              <a 
                href="https://ghiongas.et"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/60 hover:text-white transition-colors"
              >
                https://ghiongas.et
              </a>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-white font-medium mb-3">Quick Stats</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-black/20 rounded-lg p-4">
                <div className="text-white/60 text-sm">Total Owners</div>
                <div className="text-white text-lg font-medium">{owners.governance.length + owners.regular.length}</div>
              </div>
              <div className="bg-black/20 rounded-lg p-4">
                <div className="text-white/60 text-sm">Active Requests</div>
                <div className="text-white text-lg font-medium">{businessRequests.length}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedSection === 'owners' && (
        <div className="p-6 border-t border-white/10">
          <div className="mb-6">
            <h3 className="text-white font-medium mb-3">Governance Wallets</h3>
            <div className="space-y-2">
              {owners.governance.map((owner) => (
                <div key={owner.wallet} className="bg-black/20 rounded-lg p-4">
                  <div className="flex justify-between mb-1">
                    <span className="text-white font-medium">{owner.label}</span>
                    <span className="text-white">{((owner.shares / owners.totalShares) * 100).toFixed(1)}%</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-white/60">{owner.wallet.slice(0, 6)}...{owner.wallet.slice(-4)}</span>
                    <span className="text-white/60 flex items-center gap-1">
                      <MapPin className="w-4 h-4" />
                      {calculateDistance(owner.location)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-white font-medium mb-3">Regular Owners</h3>
            <div className="space-y-2">
              {owners.regular.map((owner) => (
                <div key={owner.wallet} className="bg-black/20 rounded-lg p-4">
                  <div className="flex justify-between mb-1">
                    <span className="text-white font-medium">{owner.label}</span>
                    <span className="text-white">{((owner.shares / owners.totalShares) * 100).toFixed(1)}%</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-white/60">{owner.wallet.slice(0, 6)}...{owner.wallet.slice(-4)}</span>
                    <span className="text-white/60 flex items-center gap-1">
                      <MapPin className="w-4 h-4" />
                      {calculateDistance(owner.location)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {selectedSection === 'requests' && (
        <div className="p-6 border-t border-white/10 space-y-4">
          {businessRequests.map((request) => (
            <div key={request.id} className="bg-black/20 rounded-lg p-4">
              <div className="flex justify-between mb-2">
                <span className="text-white font-medium">{request.title}</span>
                <span className="text-green-500">{request.reward} SOL</span>
              </div>
              <div className="flex justify-between text-sm text-white/60">
                <span>{request.responses} responses</span>
                <span>Ends in {request.timeLeft}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {selectedSection === 'information' && (
        <div className="p-6 border-t border-white/10 space-y-4">
          {receivedInformation.map((info) => (
            <div key={info.id} className="bg-black/20 rounded-lg p-4">
              <div className="flex justify-between mb-2">
                <span className="text-white font-medium">{info.title}</span>
                <span className="text-green-500">{info.value} SOL</span>
              </div>
              <div className="flex justify-between text-sm text-white/60">
                <span>{info.from}</span>
                <span>{info.date}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Trading Interface */}
      <div className="p-6 border-t border-white/10">
        <div className="mb-4">
          <label className="block text-gray-400 mb-2">Amount</label>
          <div className="flex gap-2">
            <div className="relative flex-1">
              <input
                type="number"
                value={shareAmount}
                onChange={(e) => setShareAmount(e.target.value)}
                className="w-full bg-black/20 border border-white/10 rounded px-3 py-2 text-white"
                placeholder="Amount"
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-white/40">
                shares
              </span>
            </div>
            <div className="text-white/60 flex items-center">
              <span className="text-sm">≈ {(Number(shareAmount) * 0.1045).toFixed(4)} SOL</span>
            </div>
          </div>
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => console.log('Buy:', shareAmount)}
            className="flex-1 bg-green-500 hover:bg-green-600 text-white rounded-lg py-2 font-medium transition-colors
                     flex items-center justify-center gap-2"
          >
            <DollarSign className="w-4 h-4" />
            Buy
          </button>
          <button
            onClick={() => console.log('Sell:', shareAmount)}
            className="flex-1 bg-red-500 hover:bg-red-600 text-white rounded-lg py-2 font-medium transition-colors
                     flex items-center justify-center gap-2"
          >
            <DollarSign className="w-4 h-4" />
            Sell
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetailsPanel;