import React from 'react';
import { Clock, MapPin, Globe } from 'lucide-react';

const RequestSidebar = ({ requests, timeAgo, selectedRequest, onSelectRequest, userLocation }) => {
  const calculateDistance = (requestLocation) => {
    console.log('Calculating distance with:', { userLocation, requestLocation });
    
    if (!userLocation?.lat || !userLocation?.lng || !requestLocation?.lat || !requestLocation?.lng) {
      console.log('Missing location data');
      return null;
    }
    
    try {
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
        new window.google.maps.LatLng(requestLocation.lat, requestLocation.lng)
      );
      console.log('Calculated distance:', distance);
      return distance;
    } catch (error) {
      console.error('Error calculating distance:', error);
      return null;
    }
  };

  const formatDistance = (distance, request) => {
    console.log('Formatting distance:', { distance, request, userLocation });

    if (!userLocation) {
      return { text: 'Enable location', available: false };
    }

    if (request.boundaryType === 'global' || !request.makerLocation) {
      return { text: 'Global', available: true, global: true };
    }

    if (distance === null) {
      return { text: 'Location error', available: false };
    }

    // Convert meters to km and miles
    const km = distance / 1000;
    const miles = distance / 1609.34;
    const radiusKm = request.boundary?.radius || 5;

    const isWithinRadius = km <= radiusKm;

    if (miles >= 1000) {
      return {
        text: `${Math.round(miles/1000)}k miles away`,
        available: isWithinRadius
      };
    } else if (miles >= 1) {
      return {
        text: `${Math.round(miles)} miles away`,
        available: isWithinRadius
      };
    } else if (km >= 1) {
      return {
        text: `${km.toFixed(1)}km away`,
        available: isWithinRadius
      };
    } else {
      return {
        text: `${Math.round(distance)}m away`,
        available: true
      };
    }
  };

  const isCustomRegion = (request) => {
    return request.boundaryType === 'custom' && request.boundary?.points?.length > 0;
  };

  return (
    <div className="w-[400px] bg-[#1a1f2e] rounded-lg overflow-hidden">
      <div className="h-[calc(100vh-200px)] overflow-y-auto">
        {requests.map((request) => {
          const distance = calculateDistance(request.makerLocation);
          const distanceInfo = formatDistance(distance, request);
          const isCustom = isCustomRegion(request);

          return (
            <div
              key={request.id}
              onClick={() => onSelectRequest(request)}
              className={`p-6 border-b border-white/10 cursor-pointer hover:bg-white/5 
                        ${selectedRequest?.id === request.id ? 'bg-white/5' : ''}`}
            >
              <div className="flex justify-between items-start mb-2">
                <div>
                  <span className="text-green-400 font-medium">
                    ${request.reward || request.currentPrice}
                  </span>
                  <span className="text-gray-500 text-sm ml-2">
                    {timeAgo(request.createdAt)}
                  </span>
                </div>
                <div className="bg-green-500/20 px-2 py-1 rounded text-xs text-green-400 font-medium">
                  {request.type || 'TEXT'}
                </div>
              </div>

              <h3 className="text-white mb-4">{request.question}</h3>
              
              <div className="flex items-center justify-between text-sm text-white/60">
                <div className="flex items-center gap-2">
                  <Clock className="w-4 h-4" />
                  <span>Ends in {request.timeLimit}</span>
                </div>
                <div className="flex items-center gap-1">
                  {distanceInfo.global ? (
                    <>
                      <Globe className="w-4 h-4" />
                      <span className="text-xs text-blue-500">Global</span>
                    </>
                  ) : isCustom ? (
                    <>
                      <MapPin className="w-4 h-4" />
                      <span className={`text-xs ${distanceInfo.available ? 'text-green-500' : 'text-red-500'}`}>
                        {distanceInfo.available ? 'In Region' : 'Outside Region'}
                      </span>
                    </>
                  ) : (
                    <>
                      <MapPin className="w-4 h-4" />
                      <span className={`text-xs ${distanceInfo.available ? 'text-green-500' : 'text-red-500'}`}>
                        {distanceInfo.text}
                        {!distanceInfo.available && ' • Not available'}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {requests.length === 0 && (
          <div className="p-6 text-center text-white/40">
            No requests found
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestSidebar;