import React, { useState, useEffect } from 'react';
import { X, Clock, Upload, Trash2 } from 'lucide-react';
import { useWallet } from '@solana/wallet-adapter-react';
import { collection, addDoc, query, orderBy, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { db } from './lib/firebase';

const DetailsPanel = ({ request, onClose, userId, userLocation }) => {
  const [infoText, setInfoText] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [responses, setResponses] = useState([]);
  const wallet = useWallet();

  useEffect(() => {
    if (request?.id) {
      const q = query(
        collection(db, "requests", request.id, "responses"),
        orderBy("createdAt", "desc")
      );
      
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const responseData = [];
        snapshot.forEach((doc) => {
          responseData.push({ id: doc.id, ...doc.data() });
        });
        setResponses(responseData);
      });

      return () => unsubscribe();
    }
  }, [request?.id]);

  const calculateDistance = (requestLocation) => {
    if (!userLocation || !requestLocation?.lat || !requestLocation?.lng) return null;
    
    return window.google.maps.geometry.spherical.computeDistanceBetween(
      new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
      new window.google.maps.LatLng(requestLocation.lat, requestLocation.lng)
    );
  };

  const isRequestAvailable = () => {
    if (!userLocation || !request.makerLocation) return false;
    
    // Always allow the creator to interact with their request
    if (request.authorId === userId) return true;

    if (request.boundaryType === 'custom' && request.boundary?.points) {
      // Check if user is inside custom polygon
      const userLatLng = new window.google.maps.LatLng(userLocation.lat, userLocation.lng);
      const polygon = new window.google.maps.Polygon({
        paths: request.boundary.points.map(point => ({
          lat: point.lat,
          lng: point.lng
        }))
      });
      return window.google.maps.geometry.poly.containsLocation(userLatLng, polygon);
    } else {
      // Check radius-based boundary
      const distance = calculateDistance(request.makerLocation);
      const radiusInMeters = (request.boundary?.radius || 5) * 1000;
      return distance !== null && distance <= radiusInMeters;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!wallet.connected) {
      alert('Please connect your wallet first');
      return;
    }

    // Check if user is within boundary
    if (!isRequestAvailable()) {
      alert('You must be within the request boundary to submit a response');
      return;
    }

    try {
      const responseRef = collection(db, "requests", request.id, "responses");
      await addDoc(responseRef, {
        text: infoText,
        author: wallet.publicKey?.toString(),
        authorId: userId,
        createdAt: new Date().toISOString(),
        status: 'pending',
        fileName: selectedFile ? selectedFile.name : null
      });

      setInfoText('');
      setSelectedFile(null);
    } catch (error) {
      console.error('Error submitting response:', error);
      alert('Failed to submit response: ' + error.message);
    }
  };

  const handleDeleteRequest = async () => {
    if (!request.id || request.authorId !== userId) return;

    try {
      await deleteDoc(doc(db, "requests", request.id));
      onClose();
    } catch (error) {
      console.error('Error deleting request:', error);
      alert('Failed to delete request: ' + error.message);
    }
  };

  const handleDeleteResponse = async (responseId) => {
    if (!responseId) return;
    
    try {
      await deleteDoc(doc(db, "requests", request.id, "responses", responseId));
    } catch (error) {
      console.error('Error deleting response:', error);
      alert('Failed to delete response: ' + error.message);
    }
  };

  if (!request) return null;

  const isOwner = request.authorId === userId;
  const available = isRequestAvailable();

  return (
    <div className="w-[400px] bg-[#1a1f2e] rounded-lg overflow-hidden ml-4">
      <div className="h-full flex flex-col max-h-[calc(100vh-200px)]">
        {/* Header */}
        <div className="flex-none p-6 border-b border-white/10">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-medium text-white">
              {isOwner ? 'Your Request' : 'Submit Response'}
            </h2>
            <div className="flex items-center gap-2">
              {isOwner && (
                <button 
                  onClick={handleDeleteRequest}
                  className="text-red-500 hover:text-red-400 transition-colors"
                  title="Delete Request"
                >
                  <Trash2 size={20} />
                </button>
              )}
              <button onClick={onClose} className="text-white/60 hover:text-white">
                <X size={20} />
              </button>
            </div>
          </div>

          <h3 className="text-white font-medium mb-2">{request.question}</h3>
          <div className="flex items-center justify-between text-sm text-white/60">
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4" />
              <span>Ends in {request.timeLimit}</span>
            </div>
            {request.boundaryType === 'custom' ? (
              <span className={available ? 'text-green-500' : 'text-red-500'}>
                {available ? 'In Region' : 'Outside Region'}
              </span>
            ) : (
              <span className={available ? 'text-green-500' : 'text-red-500'}>
                {available ? 'In Range' : 'Out of Range'}
              </span>
            )}
          </div>
        </div>

        {/* Form Section */}
        {!isOwner && (
          <div className="flex-none p-6 border-b border-white/10">
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-gray-400 mb-2">Your Response</label>
                <textarea
                  value={infoText}
                  onChange={(e) => setInfoText(e.target.value)}
                  className="w-full h-32 bg-[#262d40] rounded-lg p-4 text-white 
                          placeholder:text-white/40 border border-transparent
                          focus:border-green-500/50 resize-none"
                  placeholder={available ? "Enter your response..." : "You must be within range to respond"}
                  required
                  disabled={!available}
                />
              </div>

              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    onClick={() => document.getElementById('file-upload').click()}
                    className="px-4 py-2 bg-[#262d40] rounded-lg text-white/60
                           hover:text-white transition-colors flex items-center gap-2"
                    disabled={!available}
                  >
                    <Upload className="w-4 h-4" />
                    Upload File
                  </button>
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    disabled={!available}
                  />
                  {selectedFile && (
                    <span className="text-sm text-white/60">
                      {selectedFile.name}
                    </span>
                  )}
                </div>
              </div>

              <button
                type="submit"
                className={`w-full py-3 rounded-lg font-medium
                        transition-colors ${
                          available
                            ? 'bg-green-500 text-white hover:bg-green-600'
                            : 'bg-gray-500 text-white/60 cursor-not-allowed'
                        }`}
                disabled={!available}
              >
                {available ? 'Submit Response' : 'Not Available in Your Area'}
              </button>
            </form>

            <div className="mt-4 text-sm text-white/60">
              <span>Reward: </span>
              <span className="text-green-400 font-medium">${request.reward}</span>
            </div>
          </div>
        )}

        {/* Responses Section */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-6">
            <h3 className="text-white font-medium mb-4 sticky top-0 bg-[#1a1f2e]">
              Responses ({responses.length})
            </h3>
            <div className="space-y-4">
              {responses.map((response) => (
                <div key={response.id} className="bg-[#262d40] rounded-lg p-4">
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <span className="text-sm text-white/60">
                        {response.authorId === userId ? 'You' : response.author?.slice(0, 8) + '...'}
                      </span>
                      <span className="text-xs text-white/40 ml-2">
                        {new Date(response.createdAt).toLocaleString()}
                      </span>
                    </div>
                    {(isOwner || response.authorId === userId) && (
                      <button
                        onClick={() => handleDeleteResponse(response.id)}
                        className="text-red-500 hover:text-red-400 transition-colors"
                        title="Delete Response"
                      >
                        <Trash2 size={16} />
                      </button>
                    )}
                  </div>
                  <p className="text-white">{response.text}</p>
                  {response.fileName && (
                    <div className="mt-2 text-xs text-white/40">
                      📎 {response.fileName}
                    </div>
                  )}
                </div>
              ))}
              {responses.length === 0 && (
                <div className="text-center text-white/40">
                  No responses yet
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPanel;