// DrawingMap.js
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Pencil, MapPin, Search } from 'lucide-react';

const DrawingMap = ({ userLocation, onRegionChange }) => {
  const [map, setMap] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawnPoints, setDrawnPoints] = useState([]);
  const [center, setCenter] = useState(userLocation || { lat: 42.3601, lng: -71.0589 });
  const polygonRef = useRef(null);
  const searchInputRef = useRef(null);

  const onMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);

    const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current, {
      fields: ['address_components', 'geometry', 'formatted_address']
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;

      const newCenter = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };

      setCenter(newCenter);
      mapInstance.panTo(newCenter);
      mapInstance.setZoom(15);
    });
  }, []);

  const clearDrawing = useCallback(() => {
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
      polygonRef.current = null;
    }
    setDrawnPoints([]);
  }, []);

  const startDrawing = useCallback(() => {
    clearDrawing();
    setIsDrawing(true);
  }, [clearDrawing]);

  const handleMapClick = useCallback((event) => {
    if (!isDrawing || !event.latLng) return;

    try {
      const newPoint = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      setDrawnPoints(prev => [...prev, newPoint]);
    } catch (error) {
      console.error('Error adding point:', error);
    }
  }, [isDrawing]);

  useEffect(() => {
    if (!map || drawnPoints.length === 0) return;

    try {
      if (polygonRef.current) {
        polygonRef.current.setPath(drawnPoints.map(point =>
          new window.google.maps.LatLng(point.lat, point.lng)
        ));
      } else {
        polygonRef.current = new window.google.maps.Polygon({
          path: drawnPoints.map(point =>
            new window.google.maps.LatLng(point.lat, point.lng)
          ),
          map: map,
          strokeColor: '#f97316',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#f97316',
          fillOpacity: 0.2
        });
      }
    } catch (error) {
      console.error('Error updating polygon:', error);
    }
  }, [map, drawnPoints]);

  const finishDrawing = useCallback(() => {
    if (drawnPoints.length < 3) {
      alert('Please draw at least 3 points to create a region');
      return;
    }

    try {
      setIsDrawing(false);

      const boundaryData = {
        points: drawnPoints,
        center: {
          lat: drawnPoints.reduce((sum, point) => sum + point.lat, 0) / drawnPoints.length,
          lng: drawnPoints.reduce((sum, point) => sum + point.lng, 0) / drawnPoints.length
        },
        area: window.google.maps.geometry.spherical.computeArea(
          drawnPoints.map(point => new window.google.maps.LatLng(point.lat, point.lng))
        ) / 1000000
      };

      onRegionChange(boundaryData);
    } catch (error) {
      console.error('Error finishing drawing:', error);
      alert('Error saving region. Please try again.');
    }
  }, [drawnPoints, onRegionChange]);

  return (
    <div>
      <div className="relative mb-4">
        <input
          ref={searchInputRef}
          type="text"
          placeholder="Search for a location to focus the map"
          className="w-full bg-[#1f2637] rounded-lg p-4 pl-12 text-white 
                    border border-[#2a3142] focus:outline-none focus:border-orange-500"
        />
        <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
      </div>

      <div className="h-64 rounded-lg overflow-hidden relative">
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-20 flex gap-2">
          <button
            onClick={() => {
              if (map && userLocation) {
                map.panTo(userLocation);
                map.setZoom(15);
                setCenter(userLocation);
              }
            }}
            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-white/90 backdrop-blur 
                     hover:bg-white transition-colors shadow-lg text-gray-900"
            disabled={!userLocation}
            title={userLocation ? "Center on your location" : "Location not available"}
          >
            <MapPin size={16} className={userLocation ? "text-orange-500" : "text-gray-400"} />
          </button>

          <button
            onClick={isDrawing ? finishDrawing : startDrawing}
            type="button"
            className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
              isDrawing ? 'bg-green-500 hover:bg-green-600' : 'bg-orange-500 hover:bg-orange-600'
            } text-white transition-colors shadow-lg`}
          >
            <Pencil size={16} />
            {isDrawing ? 'Finish Drawing' : 'Draw Region'}
          </button>
        </div>

        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={center}
          zoom={15}
          onLoad={onMapLoad}
          onClick={handleMapClick}
          options={{
            mapTypeId: "satellite",
            disableDefaultUI: false,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: window.google?.maps?.ControlPosition?.TOP_LEFT || 3,
              mapTypeIds: ["satellite", "terrain"],
              style: 2
            }
          }}
        />

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black/75 text-white px-4 py-2 rounded-full text-sm z-10">
          {isDrawing 
            ? 'Click points on the map to draw your region. Click "Finish Drawing" when done.' 
            : drawnPoints.length > 0 
              ? 'Click "Finish Drawing" to save your region' 
              : 'Click "Draw Region" to begin'}
        </div>
      </div>
    </div>
  );
};

export default DrawingMap;
