import React, { useState, useEffect, useRef } from 'react';
import { Search, Menu, Coins, ArrowUpRight, ArrowDownRight, Store, Plus, MapPin } from 'lucide-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { LineChart, Line, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import RequestSidebar from './RequestSidebar';
import DetailsPanel from './DetailsPanel';
import TransactionHistory from './TransactionHistory';
import BusinessDetailsPanel from './BusinessDetailsPanel'
const RECIPIENT_WALLET = "Ai5oNL8qvQGE2dbYjzXahYvT44KoQ73zrUwsoPNr6PNB";

const LocationAdjustModal = ({ isOpen, onClose, userLocation, onConfirm }) => {
  const [map, setMap] = useState(null);
  const [adjustedLocation, setAdjustedLocation] = useState(userLocation);
  const [address, setAddress] = useState('');
  const searchInputRef = useRef(null);
  const geocoder = useRef(null);

  useEffect(() => {
    if (userLocation) {
      setAdjustedLocation(userLocation);
      if (!geocoder.current) {
        geocoder.current = new window.google.maps.Geocoder();
      }
      geocoder.current.geocode(
        { location: userLocation },
        (results, status) => {
          if (status === 'OK' && results[0]) {
            setAddress(results[0].formatted_address);
          }
        }
      );
    }
  }, [userLocation]);

  const handleMapLoad = (mapInstance) => {
    setMap(mapInstance);
    if (userLocation) {
      mapInstance.panTo(userLocation);
      mapInstance.setZoom(19);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!address.trim() || !geocoder.current) return;

    geocoder.current.geocode({ address }, (results, status) => {
      if (status === 'OK' && results[0] && results[0].geometry) {
        const location = results[0].geometry.location;
        const newLoc = {
          lat: location.lat(),
          lng: location.lng()
        };

        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
          new window.google.maps.LatLng(newLoc.lat, newLoc.lng)
        );

        if (distance <= 100) {
          setAdjustedLocation(newLoc);
          map?.panTo(newLoc);
          map?.setZoom(19);
        } else {
          alert('Selected location is outside the allowed 100m radius');
        }
      }
    });
  };

  const handleConfirm = () => {
    if (adjustedLocation && onConfirm) {
      onConfirm({
        lat: adjustedLocation.lat,
        lng: adjustedLocation.lng,
        address: address
      });
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50">
      <div className="bg-[#1a1f2e] rounded-xl w-full max-w-lg mx-4">
        <div className="p-6">
          <h2 className="text-xl text-white mb-4">Adjust Your Location</h2>

          <form onSubmit={handleSearch} className="flex gap-2 mb-4">
            <input
              ref={searchInputRef}
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="flex-1 bg-[#262d40] rounded-lg p-3 text-white 
                     border border-transparent focus:border-green-500"
              placeholder="Enter address within 100m radius"
            />
            <button
              type="submit"
              className="px-4 bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              Search
            </button>
          </form>

          <div className="h-72 rounded-lg overflow-hidden mb-4">
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={userLocation}
              zoom={19}
              onLoad={handleMapLoad}
              options={{
                mapTypeId: 'hybrid',
                zoomControl: true,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_RIGHT,
                }
              }}
            >
              {userLocation && (
                <>
                  <Circle
                    center={userLocation}
                    radius={100}
                    options={{
                      fillColor: '#22c55e',
                      fillOpacity: 0.1,
                      strokeColor: '#22c55e',
                      strokeOpacity: 0.8,
                      strokeWeight: 2
                    }}
                  />
                  <Marker
                    position={adjustedLocation}
                    draggable={true}
                    label={{
                      text: "YOU",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "14px",
                      className: "map-label"
                    }}
                    icon={{
                      url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36' height='36'%3E%3Ccircle cx='12' cy='12' r='10' fill='%23f97316' stroke='white' stroke-width='2'/%3E%3C/svg%3E",
                      anchor: new window.google.maps.Point(18, 18),
                      scaledSize: new window.google.maps.Size(36, 36)
                    }}
                    onDragEnd={(e) => {
                      if (!e.latLng) return;
                      
                      const newLat = e.latLng.lat();
                      const newLng = e.latLng.lng();
                      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                        new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
                        new window.google.maps.LatLng(newLat, newLng)
                      );
                      
                      if (distance <= 100) {
                        const newLocation = { lat: newLat, lng: newLng };
                        setAdjustedLocation(newLocation);
                        
                        if (geocoder.current) {
                          geocoder.current.geocode(
                            { location: newLocation },
                            (results, status) => {
                              if (status === 'OK' && results[0]) {
                                setAddress(results[0].formatted_address);
                              }
                            }
                          );
                        }
                      } else {
                        setAdjustedLocation(userLocation);
                      }
                    }}
                  />
                </>
              )}
            </GoogleMap>
          </div>

          <div className="text-sm text-white/60 mb-4">
            Drag the marker within the highlighted 100m radius to adjust your location
          </div>

          <div className="flex gap-4">
            <button
              onClick={onClose}
              className="flex-1 py-2 rounded-lg text-white hover:bg-[#262d40] transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="flex-1 py-2 rounded-lg bg-green-500 text-white hover:bg-green-600 transition-colors"
            >
              Confirm Location
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const BusinessList = ({ businesses, onSelectBusiness, onAddBusiness }) => {
  return (
    <div className="w-[400px] bg-[#1a1f2e] rounded-lg overflow-hidden">
      <div className="p-6 border-b border-white/10">
        <div className="text-lg text-white font-medium mb-4">Add Existing Business</div>
        <button
          onClick={onAddBusiness}
          className="w-full py-3 text-[#1a1f2e] bg-white rounded-lg font-medium 
                   hover:bg-white/90 transition-colors flex items-center justify-center gap-2"
        >
          <Plus className="w-5 h-5" />
          Add Business
        </button>
      </div>
      <div className="h-[calc(100vh-200px)] overflow-y-auto">
        <div className="space-y-4 p-6">
          {businesses?.length ? (
            businesses.map((business) => (
              <div
                key={business.id}
                onClick={() => onSelectBusiness(business)}
                className="bg-[#262d40] rounded-lg p-4 cursor-pointer hover:bg-[#2a3142] transition-colors"
              >
                <div className="flex items-center gap-3">
                  <Store className="w-6 h-6 text-green-500" />
                  <div>
                    <h3 className="text-white font-medium">{business.name}</h3>
                    <p className="text-sm text-white/60">
                      {business.address || 'No address provided'}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-white/40">
              No businesses found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SidebarContainer = ({
  activeTab,
  setActiveTab,
  searchTerm,
  setSearchTerm,
  requests,
  businesses,
  timeAgo,
  selectedRequest,
  selectedBusiness,
  handleItemSelect,
  transactions,
  userLocation,
  userId,
  localLensBalance,
  onBuyTokens,
  purchaseHistory,
  onAddBusiness,
  onUpdateLocation
}) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const [showTransactions, setShowTransactions] = useState(false);
  const [showRequestsList, setShowRequestsList] = useState(true);
  const [showTokenControls, setShowTokenControls] = useState(false);
  const [tokenAmount, setTokenAmount] = useState('100');
  const [priceHistory, setPriceHistory] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(0.1);
  const [isLoading, setIsLoading] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(true); // Add this line
  
  useEffect(() => {
    const generatePriceHistory = () => {
      const basePrice = 0.1;
      const history = [];
      for (let i = 0; i < 24; i++) {
        const variance = (Math.random() - 0.5) * 0.02;
        history.push({
          time: i,
          price: basePrice + variance
        });
      }
      return history;
    };
    setPriceHistory(generatePriceHistory());
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName.toLowerCase());
    setShowRequestsList(true);
  };

  const handleTransaction = async (isBuy, amount) => {
    if (!wallet.connected) {
      alert('Please connect your wallet first');
      return;
    }

    if (!userLocation) {
      alert('Please enable location access to use LocalLens tokens');
      return;
    }

    setIsLoading(true);
    try {
      const recipientPubKey = new PublicKey(RECIPIENT_WALLET);
      const solAmount = amount * currentPrice;
      const lamports = solAmount * LAMPORTS_PER_SOL;

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: wallet.publicKey,
          toPubkey: recipientPubKey,
          lamports,
        })
      );

      const signature = await wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'confirmed');

      if (isBuy) {
        onBuyTokens(Number(amount), currentPrice);
      }
      
      setShowTokenControls(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Transaction failed:', error);
      alert('Transaction failed: ' + error.message);
      setIsLoading(false);
    }
  };

  const priceChange = priceHistory.length > 1 
    ? ((priceHistory[priceHistory.length - 1].price - priceHistory[0].price) / priceHistory[0].price) * 100 
    : 0;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-[#1a1f2e] p-2 rounded border border-white/10 text-xs">
          <p className="text-white">{`${payload[0].value.toFixed(4)} SOL`}</p>
        </div>
      );
    }
    return null;
  };

  const renderSidebarContent = () => {
    switch (activeTab) {
      case 'businesses':
        return (
          <div className="flex">
            <BusinessList 
              businesses={businesses}
              onSelectBusiness={(business) => handleItemSelect(business, 'business')}
              onAddBusiness={onAddBusiness}
            />
            {selectedBusiness && (
              <BusinessDetailsPanel
                business={selectedBusiness}
                onClose={() => handleItemSelect(null, null)}
              />
            )}
          </div>
        );
      case 'requests':
        return showRequestsList && (
          <RequestSidebar
            requests={requests}
            timeAgo={timeAgo}
            selectedRequest={selectedRequest}
            onSelectRequest={(request) => handleItemSelect(request, 'request')}
            userLocation={userLocation}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TransactionHistory isOpen={showTransactions} transactions={transactions} />

      <div className={`absolute top-0 right-0 left-0 z-50 transition-all duration-300 ${
        showTransactions ? 'ml-[320px]' : ''
      }`}>
        <div className="backdrop-blur-sm">
          <div className="px-4 pt-2 flex items-center gap-4">
            <button 
              className="p-2 rounded-lg bg-[#1a1f2e]/60 backdrop-blur-sm"
              onClick={() => setShowTransactions(!showTransactions)}
            >
              <Menu className="w-6 h-6 text-white" />
            </button>
            <div className="flex-1 flex items-center justify-between gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder={`Search ${activeTab}...`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full h-12 bg-[#1a1f2e]/60 backdrop-blur-sm text-white rounded-lg pl-12 pr-4 
                           border border-transparent focus:border-green-500/50"
                />
                <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50" size={20} />
              </div>
              <div className="z-50 flex items-center gap-4">
                {/* LocalLens Token Display */}
                <div 
  className="relative"
  onMouseEnter={() => setShowTokenControls(true)}
  onMouseLeave={() => !isLoading && setShowTokenControls(false)}
>
  <div className="flex items-center gap-3 bg-[#1a1f2e]/60 backdrop-blur-sm px-4 py-2 rounded-lg cursor-pointer">
    <div className="flex items-center gap-2">
      <Coins className="w-5 h-5 text-yellow-500" />
      <span className="text-white font-medium">{localLensBalance.toLocaleString()} LL</span>
    </div>
    
    <div className="h-8 w-24">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={priceHistory}>
          <Line 
            type="monotone" 
            dataKey="price" 
            stroke={priceChange >= 0 ? "#22c55e" : "#ef4444"} 
            dot={false} 
            strokeWidth={2}
          />
          <YAxis domain={['dataMin', 'dataMax']} hide={true} />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    </div>

    <div className="flex items-center gap-1 min-w-[80px]">
      <span className="text-white/80 text-sm">{currentPrice.toFixed(4)} SOL</span>
      <div className={`text-xs flex items-center ${priceChange >= 0 ? 'text-green-500' : 'text-red-500'}`}>
        {priceChange >= 0 ? <ArrowUpRight className="w-3 h-3" /> : <ArrowDownRight className="w-3 h-3" />}
        {Math.abs(priceChange).toFixed(1)}%
      </div>
    </div>

    {/* Status Indicators */}
    <div className="flex items-center gap-2 ml-2 border-l border-white/10 pl-2">
      {/* Online/Offline Status */}
      <div className={`flex items-center gap-1 ${onlineStatus ? 'text-green-500' : 'text-gray-500'}`}>
        <div className="w-2 h-2 rounded-full bg-current"/>
        <span className="text-xs">
          {onlineStatus ? 'Online' : 'Offline'}
        </span>
      </div>

      {/* Location Status */}
      <button
        onClick={() => setShowLocationModal(true)}
        className={`flex items-center gap-1 ${userLocation ? 'text-green-500' : 'text-gray-500'}`}
      >
        <MapPin className="w-4 h-4" />
        <span className="text-xs">
          {userLocation ? 'Live' : 'Offline'}
        </span>
      </button>

      {/* Market Cap */}
      <div className="ml-2 text-white/60">
        <span className="text-xs">MC:</span>
        <span className="text-sm font-medium ml-1">
          {(localLensBalance * currentPrice).toFixed(2)} SOL
        </span>
      </div>
    </div>
  </div>

                  {showTokenControls && (
                    <div className="absolute top-full mt-2 right-0 bg-[#1a1f2e] rounded-lg border border-white/10 shadow-xl p-4 w-72">
                      <div className="flex gap-2 mb-4">
                        <input
                          type="number"
                          value={tokenAmount}
                          onChange={(e) => setTokenAmount(e.target.value)}
                          className="flex-1 bg-black/20 border border-white/10 rounded px-3 py-1.5 text-white"
                          placeholder="Amount"
                        />
                        <div className="text-white/60 flex items-center">
                          <span className="text-sm">≈ {(Number(tokenAmount) * currentPrice).toFixed(4)} SOL</span>
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <button
                          onClick={() => handleTransaction(true, Number(tokenAmount))}
                          disabled={isLoading || !userLocation}
                          className="flex-1 bg-green-500 hover:bg-green-600 text-white rounded py-2 font-medium transition-colors
                                   disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {!userLocation ? 'Enable Location' : isLoading ? 'Processing...' : 'Buy'}
                        </button>
                        <button
                          onClick={() => handleTransaction(false, Number(tokenAmount))}
                          disabled={isLoading || localLensBalance < Number(tokenAmount) || !userLocation}
                          className="flex-1 bg-red-500 hover:bg-red-600 text-white rounded py-2 font-medium transition-colors
                                   disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {isLoading ? 'Processing...' : 'Sell'}
                        </button>
                      </div>

                      {!userLocation && (
                        <div className="mt-4 text-sm text-red-400">
                          Location access is required to use LocalLens tokens
                        </div>
                      )}

                      <div className="mt-4 text-xs text-white/40">
                        Recent Transactions:
                        <div className="mt-2 space-y-1">
                          {purchaseHistory.slice(-3).reverse().map((purchase, i) => (
                            <div key={i} className="flex justify-between">
                              <span>{purchase.amount} LL</span>
                              <span>{purchase.pricePerToken} SOL</span>
                              <span>{new Date(purchase.timestamp).toLocaleTimeString()}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Solana Wallet Button */}
                <WalletMultiButton className="!bg-[#1a1f2e]/60 hover:!bg-[#262d40]/60" />
              </div>
            </div>
          </div>

          <div className="px-4 pt-2 pb-2 flex items-center gap-2 overflow-x-auto">
            {['Requests', 'Information', 'People', 'Businesses'].map((tab) => (
              <button
                key={tab.toLowerCase()}
                onClick={() => handleTabClick(tab)}
                className={`px-4 py-2 rounded-full whitespace-nowrap ${
                  activeTab === tab.toLowerCase() 
                  ? 'bg-green-500 text-white' 
                  : 'bg-[#1a1f2e]/60 backdrop-blur-sm text-white/70 hover:text-white'
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className={`absolute top-44 left-4 flex h-[calc(100vh-200px)] transition-all duration-300 ${
        showTransactions ? 'ml-[320px]' : ''
      }`}>
        {renderSidebarContent()}

        {selectedRequest && (
          <DetailsPanel
            request={selectedRequest}
            onClose={() => handleItemSelect(null, null)}
            userId={userId}
            userLocation={userLocation}
          />
        )}
      </div>

      <LocationAdjustModal
        isOpen={showLocationModal}
        onClose={() => setShowLocationModal(false)}
        userLocation={userLocation}
        onConfirm={onUpdateLocation}
      />
    </>
  );
};

export default SidebarContainer;