import React, { useState, useEffect } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { useWallet } from '@solana/wallet-adapter-react';
import { collection, addDoc, onSnapshot, query, orderBy, doc, setDoc, getDoc } from 'firebase/firestore';
import SidebarContainer from './SidebarContainer';
import MainMap from './MainMap';
import PostRequestForm from './PostRequestForm';
import BusinessForm from './BusinessForm';
import { db } from './lib/firebase';
import { X, MapPin, Plus } from 'lucide-react';
import '@solana/wallet-adapter-react-ui/styles.css';

const GOOGLE_MAPS_API_KEY = 'YOUR_API_KEY';
const MASTER_WALLET = "Ai5oNL8qvQGE2dbYjzXahYvT44KoQ73zrUwsoPNr6PNB";
const libraries = ["places", "visualization", "geometry", "drawing"];

const App = () => {
  const wallet = useWallet();
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 20, lng: 0 });
  const [userLocation, setUserLocation] = useState(null);
  const [locationStatus, setLocationStatus] = useState('pending');
  const [activeTab, setActiveTab] = useState('requests');
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [isAddBusinessMode, setIsAddBusinessMode] = useState(false);
  const [requests, setRequests] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAvailable, setShowAvailable] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedInformation, setSelectedInformation] = useState(null);
  const [localLensBalance, setLocalLensBalance] = useState(0);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [isBusinessFormOpen, setIsBusinessFormOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationError, setLocationError] = useState('');

  // Fetch requests
  useEffect(() => {
    const q = query(collection(db, "requests"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const requestsData = [];
      querySnapshot.forEach((doc) => {
        requestsData.push({ id: doc.id, ...doc.data() });
      });
      setRequests(requestsData);
    });
    return () => unsubscribe();
  }, []);

  // Fetch businesses
  useEffect(() => {
    const q = query(collection(db, "businesses"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const businessData = [];
      querySnapshot.forEach((doc) => {
        businessData.push({ id: doc.id, ...doc.data() });
      });
      setBusinesses(businessData);
    });
    return () => unsubscribe();
  }, []);

  // Fetch user data
  useEffect(() => {
    const fetchTokenData = async () => {
      if (wallet.publicKey) {
        const userRef = doc(db, "users", wallet.publicKey.toString());
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setLocalLensBalance(userData.localLensBalance || 0);
          setPurchaseHistory(userData.purchaseHistory || []);
          
          if (userData.location) {
            setUserLocation(userData.location);
            setSelectedLocation(userData.location);
          }
        } else {
          await setDoc(userRef, {
            localLensBalance: 0,
            purchaseHistory: []
          });
        }
      }
    };

    if (wallet.connected) {
      fetchTokenData();
    } else {
      setLocalLensBalance(0);
      setPurchaseHistory([]);
    }
  }, [wallet.connected, wallet.publicKey]);

  const handleUpdateLocation = async (newLocation) => {
    if (!wallet.connected) return;

    try {
      const userRef = doc(db, "users", wallet.publicKey.toString());
      await setDoc(userRef, {
        location: newLocation
      }, { merge: true });

      setUserLocation(newLocation);
      setSelectedLocation(newLocation);
      setLocationError('');

      if (map) {
        map.panTo(newLocation);
        map.setZoom(18);
      }
    } catch (error) {
      console.error('Error updating location:', error);
      setLocationError('Failed to update location');
    }
  };

  const handlePostRequest = async (formData) => {
    try {
      const newRequest = {
        author: wallet.publicKey?.toString() || "Anonymous",
        authorId: wallet.publicKey?.toString(),
        posted: 'just now',
        reward: formData.reward,
        currentPrice: formData.reward,
        originalPrice: formData.reward,
        question: formData.question,
        timeLimit: `${formData.timeLimit} ${Number(formData.timeLimit) === 1 ? 'hour' : 'hours'}`,
        infoCount: 0,
        makerLocation: userLocation,
        boundary: formData.boundary,
        boundaryType: formData.boundaryType || 'radius',
        createdAt: new Date().toISOString(),
        status: 'active',
        isBot: false,
        type: formData.type || 'TEXT'
      };

      await addDoc(collection(db, "requests"), newRequest);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error('Error in handlePostRequest:', error);
      alert('Failed to save request: ' + error.message);
    }
  };

  const handleBuyTokens = async (amount, pricePerToken) => {
    if (!wallet.connected) return;

    try {
      const userRef = doc(db, "users", wallet.publicKey.toString());
      const userDoc = await getDoc(userRef);
      const currentBalance = userDoc.exists() ? userDoc.data().localLensBalance || 0 : 0;
      const currentHistory = userDoc.exists() ? userDoc.data().purchaseHistory || [] : [];

      const purchase = {
        amount,
        pricePerToken,
        timestamp: new Date().toISOString()
      };

      await setDoc(userRef, {
        localLensBalance: currentBalance + amount,
        purchaseHistory: [...currentHistory, purchase]
      }, { merge: true });

      setLocalLensBalance(currentBalance + amount);
      setPurchaseHistory([...currentHistory, purchase]);
    } catch (error) {
      console.error('Error buying tokens:', error);
      alert('Failed to purchase tokens: ' + error.message);
    }
  };

  const handleAddBusiness = async (businessData) => {
    if (!wallet.connected) return;
    
    try {
      const newBusiness = {
        ...businessData,
        owner: wallet.publicKey.toString(),
        createdAt: new Date().toISOString()
      };

      await addDoc(collection(db, "businesses"), newBusiness);
      setIsBusinessFormOpen(false);
      setActiveTab('businesses');
    } catch (error) {
      console.error('Error adding business:', error);
      alert('Failed to add business: ' + error.message);
    }
  };

  const handleItemSelect = (item, type) => {
    setSelectedRequest(null);
    setSelectedBusiness(null);
    setSelectedPerson(null);
    setSelectedInformation(null);

    switch(type) {
      case 'request':
        setSelectedRequest(item);
        break;
      case 'business':
        setSelectedBusiness(item);
        break;
      case 'person':
        setSelectedPerson(item);
        break;
      case 'information':
        setSelectedInformation(item);
        break;
      default:
        break;
    }

    if (map && item?.location) {
      map.panTo(item.location);
      map.setZoom(15);
    }
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      setLocationStatus('requesting');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setUserLocation(newLocation);
          setSelectedLocation(newLocation);
          setCenter(newLocation);
          setLocationStatus('granted');

          if (map) {
            map.panTo(newLocation);
            map.setZoom(16);
          }

          // Save location if user is logged in
          if (wallet.connected) {
            handleUpdateLocation(newLocation);
          }
        },
        (error) => {
          console.error('Error getting location:', error);
          setLocationStatus('denied');
          setLocationError('Failed to get your location');
        }
      );
    } else {
      setLocationStatus('unsupported');
      setLocationError('Your browser does not support geolocation');
    }
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const past = new Date(dateString);
    const diffInSeconds = Math.floor((now - past) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds}s ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
    return `${Math.floor(diffInSeconds / 86400)}d ago`;
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <div className="h-screen w-screen fixed inset-0 overflow-hidden">
        <MainMap
          map={map}
          setMap={setMap}
          center={center}
          userLocation={userLocation}
          requests={requests}
          businesses={businesses}
          activeTab={activeTab}
          showAvailable={showAvailable}
          setShowAvailable={setShowAvailable}
          selectedRequest={selectedRequest}
          selectedBusiness={selectedBusiness}
          userId={wallet.publicKey?.toString()}
          handleItemSelect={handleItemSelect}
        />

        <SidebarContainer
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          requests={requests}
          businesses={businesses}
          timeAgo={timeAgo}
          selectedRequest={selectedRequest}
          selectedBusiness={selectedBusiness}
          handleItemSelect={handleItemSelect}
          transactions={[]}
          userLocation={userLocation}
          userId={wallet.publicKey?.toString()}
          localLensBalance={localLensBalance}
          onBuyTokens={handleBuyTokens}
          purchaseHistory={purchaseHistory}
          onAddBusiness={() => setIsBusinessFormOpen(true)}
          onUpdateLocation={handleUpdateLocation}
        />

        {/* Post/Add Button */}
        <div className="absolute bottom-6 right-6">
          <button
            onClick={() => {
              if (!wallet.connected) {
                alert('Please connect your wallet first');
                return;
              }
              if (!userLocation) {
                getUserLocation();
              } else {
                if (activeTab === 'businesses') {
                  setIsBusinessFormOpen(true);
                } else {
                  setIsPostModalOpen(true);
                }
              }
            }}
            className="px-8 py-3 bg-green-500 text-white rounded-full font-medium 
                     hover:bg-green-600 transition-all duration-300 
                     flex items-center gap-2"
          >
            {!userLocation ? (
              <>
                <MapPin className="w-5 h-5" />
                Enable Location
              </>
            ) : activeTab === 'businesses' ? (
              <>
                <Plus className="w-5 h-5" />
                Add Business
              </>
            ) : (
              'Post Request'
            )}
          </button>
        </div>

        {/* Post Request Modal */}
        {isPostModalOpen && (
          <div className="fixed inset-0 bg-black/95 z-[100] flex items-center justify-center">
            <div className="bg-[#1a1f2e]/90 backdrop-blur-xl rounded-2xl max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto
                         border border-white/10 shadow-2xl shadow-black/50 relative">
              <div className="p-8">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-semibold text-white">Create Request</h2>
                  <button onClick={() => setIsPostModalOpen(false)} className="text-gray-400 hover:text-white transition-colors">
                    <X size={24} />
                  </button>
                </div>
                <PostRequestForm
                  onSubmit={handlePostRequest}
                  onClose={() => setIsPostModalOpen(false)}
                  userLocation={userLocation}
                  timeOptions={[
                    { value: '0.083', label: '5 minutes' },
                    { value: '0.5', label: '30 minutes' },
                    { value: '1', label: '1 hour' },
                    { value: '3', label: '3 hours' },
                    { value: '6', label: '6 hours' },
                    { value: '12', label: '12 hours' },
                    { value: '24', label: '24 hours' }
                  ]}
                />
              </div>
            </div>
          </div>
        )}

        {/* Business Form Modal */}
        {isBusinessFormOpen && (
          <div className="fixed inset-0 bg-black/95 z-[100] flex items-center justify-center">
            <div className="bg-[#1a1f2e]/90 backdrop-blur-xl rounded-2xl max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto
                         border border-white/10 shadow-2xl shadow-black/50 relative">
              <div className="p-8">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-semibold text-white">Add New Business</h2>
                  <button onClick={() => setIsBusinessFormOpen(false)} className="text-gray-400 hover:text-white transition-colors">
                    <X size={24} />
                  </button>
                </div>
                <BusinessForm
                  onSubmit={handleAddBusiness}
                  onClose={() => setIsBusinessFormOpen(false)}
                  userLocation={userLocation}
                  isMasterWallet={wallet.publicKey?.toString() === MASTER_WALLET}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </LoadScript>
  );
};

export default App;