// PostRequestForm.js
import React, { useState, useEffect,useCallback } from 'react';
import { DollarSign } from 'lucide-react';
import ScopeSection from './ScopeSection';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './lib/firebase';

const PostRequestForm = ({
  onSubmit,
  onClose,
  userLocation,
  timeOptions = [
    { value: '0.083', label: '5 minutes' },
    { value: '0.5', label: '30 minutes' },
    { value: '1', label: '1 hour' },
    { value: '3', label: '3 hours' },
    { value: '6', label: '6 hours' },
    { value: '12', label: '12 hours' },
    { value: '24', label: '24 hours' }
  ],
  initialValues = {}
}) => {
  const [formState, setFormState] = useState({
    question: initialValues.question || '',
    reward: initialValues.reward || '',
    timeLimit: initialValues.timeLimit || '1',
    scope: initialValues.scope || 'local',
    location: 'Current Location', // Set this initially
    radius: initialValues.radius || '5',
    boundaryType: 'radius', // Make sure this is set to radius by default
    customBounds: initialValues.customBounds || null,
    isAnonymous: initialValues.isAnonymous || false,
    targetLocation: userLocation ? {
      lat: userLocation.lat,
      lng: userLocation.lng
    } : null
  });

  console.log("PostRequestForm Initial State:", {
    userLocation,
    targetLocation: formState.targetLocation,
    formState
  });

  const [locationError, setLocationError] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(
    userLocation
      ? {
          place_name: 'Current Location',
          coordinates: [userLocation.lng, userLocation.lat]
        }
      : null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (userLocation && formState.scope === 'local') {
      setSelectedLocation({
        place_name: 'Current Location',
        coordinates: [userLocation.lng, userLocation.lat]
      });
      setFormState((prev) => ({
        ...prev,
        location: 'Current Location',
        targetLocation: {
          lat: userLocation.lat,
          lng: userLocation.lng
        }
      }));
    }
  }, [formState.scope, userLocation]);

  const handleInputChange = (field, value) => {
    setFormState((prev) => {
      const newState = { ...prev, [field]: value };

      if (field === 'scope') {
        if (value === 'local') {
          if (userLocation) {
            setSelectedLocation({
              place_name: 'Current Location',
              coordinates: [userLocation.lng, userLocation.lat]
            });
            newState.location = 'Current Location';
            newState.targetLocation = {
              lat: userLocation.lat,
              lng: userLocation.lng
            };
          }
        } else {
          newState.location = '';
          newState.customBounds = null;
          newState.targetLocation = null;
          setSelectedLocation(null);
        }
        setLocationError('');
      }

      if (field === 'boundaryType') {
        if (value === 'radius') {
          if (formState.location && formState.targetLocation) {
            // Keep existing targetLocation
          } else if (userLocation) {
            newState.targetLocation = {
              lat: userLocation.lat,
              lng: userLocation.lng
            };
            newState.location = 'Current Location';
          } else {
            newState.targetLocation = null;
            newState.location = '';
          }
        } else {
          newState.targetLocation = null;
        }
        newState.customBounds = null;
      }

      return newState;
    });
  };

  const handleRegionChange = (region) => {
    if (region) {
      setFormState((prev) => ({
        ...prev,
        boundaryType: 'custom',
        customBounds: {
          points: region.points,
          center: region.center,
          area: region.area
        },
        targetLocation: region.center
      }));
    }
  };

  const handleLocationSelect = (suggestion) => {
    setSelectedLocation(suggestion);
    setFormState((prev) => ({
      ...prev,
      location: suggestion.place_name,
      targetLocation: {
        lat: suggestion.coordinates[1],
        lng: suggestion.coordinates[0],
      },
    }));
    setLocationError('');
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      let submissionData = {
        question: formState.question,
        reward: formState.reward,
        timeLimit: formState.timeLimit,
        scope: formState.scope,
        boundaryType: formState.boundaryType,
        createdAt: new Date().toISOString(),
        status: 'active',
        makerLocation: userLocation
          ? {
              lat: Number(userLocation.lat),
              lng: Number(userLocation.lng)
            }
          : null
      };

      if (formState.scope === 'local') {
        if (formState.boundaryType === 'custom' && formState.customBounds) {
          submissionData.boundary = {
            type: 'custom',
            points: formState.customBounds.points.map((point) => ({
              lat: Number(point.lat),
              lng: Number(point.lng)
            })),
            center: {
              lat: Number(formState.customBounds.center.lat),
              lng: Number(formState.customBounds.center.lng)
            },
            area: Number(formState.customBounds.area)
          };
        } else if (formState.boundaryType === 'radius') {
          if (!formState.targetLocation) {
            throw new Error('Please select a location');
          }
          submissionData.boundary = {
            type: 'radius',
            center: {
              lat: Number(formState.targetLocation.lat),
              lng: Number(formState.targetLocation.lng)
            },
            radius: parseInt(formState.radius),
            address: formState.location
          };
        }
      }

      await addDoc(collection(db, 'requests'), submissionData);
      onClose();
    } catch (error) {
      console.error('Form submission error:', error);
      setLocationError(error.message);
      alert('Failed to save request: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-h-[calc(100vh-120px)] overflow-y-auto pb-6">
      <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
        {/* Base Form Fields */}
        <div className="space-y-6">
          {/* Question Input */}
          <div>
            <label className="block text-gray-400 mb-2">Request ideas for</label>
            <textarea
              value={formState.question}
              onChange={(e) => handleInputChange('question', e.target.value)}
              className="w-full min-h-[120px] bg-[#1f2637] rounded-lg p-4 text-white 
                        border border-[#2a3142] focus:outline-none focus:border-orange-500
                        resize-none"
              placeholder="What would you like to know?"
              required
            />
          </div>

          {/* Reward Input */}
          <div>
            <label className="block text-gray-400 mb-2">Reward Amount</label>
            <div className="relative">
              <div className="absolute left-4 top-1/2 -translate-y-1/2 text-green-400">
                <DollarSign size={20} />
              </div>
              <input
                type="number"
                value={formState.reward}
                onChange={(e) => handleInputChange('reward', e.target.value)}
                className="w-full bg-[#1f2637] rounded-lg p-4 pl-12 text-green-400 font-medium
                         border border-[#2a3142] focus:outline-none focus:border-orange-500"
                placeholder="0.00"
                step="0.01"
                min="0.01"
                required
              />
            </div>
          </div>

          {/* Time Limit Select */}
          <div>
            <label className="block text-gray-400 mb-2">Time Limit</label>
            <select
              value={formState.timeLimit}
              onChange={(e) => handleInputChange('timeLimit', e.target.value)}
              className="w-full bg-[#1f2637] rounded-lg p-4 text-white 
                       border border-[#2a3142] focus:outline-none focus:border-orange-500 
                       appearance-none cursor-pointer"
            >
              {timeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Scope Section */}
        <ScopeSection
          formState={formState}
          handleInputChange={handleInputChange}
          userLocation={userLocation}
          handleRegionChange={handleRegionChange}
          locationError={locationError}
          setLocationError={setLocationError}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          handleLocationSelect={handleLocationSelect}
        />

        {/* Form Buttons */}
        <div className="flex gap-4 pt-4">
          <button
            type="button"
            onClick={onClose}
            className="flex-1 py-3 rounded-lg text-white hover:bg-[#262d40] transition-colors"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 py-3 rounded-lg bg-orange-500 text-white hover:bg-orange-600 transition-colors
                     disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isSubmitting || (formState.scope === 'local' && !userLocation)}
          >
            {isSubmitting ? 'Posting...' : 'Post Request'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PostRequestForm;
